// @see adminator/src/assets/scripts/index.js
require("adminator/src/assets/scripts/masonry/index.js")
require("adminator/src/assets/scripts/charts/index.js")
require("adminator/src/assets/scripts/popover/index.js")
require("adminator/src/assets/scripts/scrollbar/index.js")
require("adminator/src/assets/scripts/search/index.js")
require("adminator/src/assets/scripts/sidebar/index.js")
require("adminator/src/assets/scripts/skycons/index.js")
require("adminator/src/assets/scripts/vectorMaps/index.js")
require("adminator/src/assets/scripts/chat/index.js")
require("adminator/src/assets/scripts/datatable/index.js")
require("adminator/src/assets/scripts/datepicker/index.js")
require("adminator/src/assets/scripts/email/index.js")
//require("adminator/src/assets/scripts/fullcalendar/index.js")
require("adminator/src/assets/scripts/googleMaps/index.js")
require("adminator/src/assets/scripts/utils/index.js")

// sidebar toggle
// $('.sidebar-toggle-search').on('click', e => {
//   $('.app').toggleClass('is-collapsed-search');
//   e.preventDefault();
// });
// $('.sidebar-toggle-notice').on('click', e => {
//   $('.app').toggleClass('is-collapsed-notice');
//   e.preventDefault();
// });
// $('.sidebar-toggle-history').on('click', e => {
//   $('.app').toggleClass('is-collapsed-history');
//   e.preventDefault();
// });
//$('.sidebar-toggle-chat').on('click', e => {
//  $('.app').toggleClass('is-collapsed-chat');
//  e.preventDefault();
//});
